<template>
  <!--begin::Analytics-->
  <div class="d-flex flex-row">
    <div class="flex-row-auto offcanvas-mobile w-300px w-xl-350px" id="kt_submenu_aside" ref="kt_submenu_aside">
      <div class="card card-custom card-stretch">
        <div class="card-body pt-4">
          <!--begin::Nav-->
          <div class="navi navi-bold navi-hover navi-active navi-link-rounded"
               role="tablist"
          >
            <p class="text-muted pt-5 pb-2">{{ $t("ANALYTICS.UTILIZATION_TITLE") }}</p>
            <div v-if="isCan('analytics-utilization-view')"
                 @click="setAmplitudeEventType('analytics-utilization-desks show')"
                 class="navi-item mb-2">
              <router-link
                  class="navi-link py-4"
                  :to="{ name: 'workplace-utilization', exact: true }"
                  active-class="active"
              >
                <span class="navi-icon mr-2">
                  <ChartBar/>
                </span>
                <span class="navi-text font-size-lg font-weight-bolder">{{ $t("ANALYTICS.DESKS_UTILIZATION_TITLE") }}</span>
              </router-link>
            </div>
            <div v-if="isCan('analytics-utilization-view')"
                 @click="setAmplitudeEventType('analytics-utilization-meeting-rooms show')"
                 class="navi-item mb-2">
              <router-link
                  class="navi-link py-4"
                  :to="{ name: 'meeting-room-utilization', exact: true }"
                  active-class="active"
              >
                <span class="navi-icon mr-2">
                  <ChartBar/>
                </span>
                <span class="navi-text font-size-lg font-weight-bolder">{{ $t("ANALYTICS.MEETING_ROOMS_UTILIZATION_TITLE") }}</span>
              </router-link>
            </div>
            <p class="text-muted pt-5 pb-2">{{ $t("ANALYTICS.OTHER_TITLE") }}</p>
            <div v-if="isCan('analytics-attendance-view')"
                 @click="setAmplitudeEventType('analytics-office-attendance show')"
                 class="navi-item mb-2">
              <router-link
                  class="navi-link py-4"
                  :to="{ name: 'office-attendance', exact: true }"
                  active-class="active"
              >
                <span class="navi-icon mr-2">
                  <ChartLine/>
                </span>
                <span class="navi-text font-size-lg font-weight-bolder">{{ $t("ANALYTICS.OFFICE_ATTENDANCE_TITLE") }}</span>
              </router-link>
            </div>
            <div v-if="isCan('analytics-attendance-view')"
                 @click="setAmplitudeEventType('analytics-employees-attendance show')"
                 class="navi-item mb-2">
              <router-link
                  class="navi-link py-4"
                  :to="{ name: 'employees-attendance', exact: true }"
                  active-class="active"
              >
                <span class="navi-icon mr-2">
                  <ChartLine/>
                </span>
                <span class="navi-text font-size-lg font-weight-bolder">{{ $t("ANALYTICS.EMPLOYEE_ATTENDANCE_TITLE") }}</span>
              </router-link>
            </div>
            <div v-if="isCan('analytics-attendance-view')"
                 @click="setAmplitudeEventType('analytics-groups-attendance show')"
                 class="navi-item mb-2">
              <router-link
                  class="navi-link py-4"
                  :to="{ name: 'groups-attendance', exact: true }"
                  active-class="active"
              >
                <span class="navi-icon mr-2">
                  <ChartLine/>
                </span>
                <span class="navi-text font-size-lg font-weight-bolder">{{ $t("ANALYTICS.GROUP_ATTENDANCE_TITLE") }}</span>
              </router-link>
            </div>
          </div>
          <!--end::Nav-->
        </div>
      </div>
    </div>
    <!--begin::Content-->
    <div class="content-wrapper flex-row-fluid ml-lg-8">
      <transition name="fade-in-up">
        <router-view />
      </transition>
    </div>
    <!--end::Content-->
  </div>
  <!--end::Analytics-->
</template>

<script>
import ChartBar from "@/view/layout/svg/ChartBar";
import ChartLine from "@/view/layout/svg/ChartLine";
import KTLayoutAside from "@/assets/js/layout/base/aside";
import { sendAmplitudeEvent } from "@/core/services/amplitude.service";

export default {
  name: "Analytics",
  components: {
    ChartBar,
    ChartLine
  },
  mounted() {
    KTLayoutAside.init(this.$refs["kt_submenu_aside"]);
  },
  data() {
    return {
      tabIndex: 0
    };
  },
  methods: {
    isCan(role) {
      if (this.$store.getters.isCan) {
        let foundRole = this.$store.getters.isCan.find(item => item.includes(role));
        return foundRole !== undefined;
      }
    },
    setAmplitudeEventType(eventType) {
      sendAmplitudeEvent(eventType);
    }
  }
}
</script>

<style>
  .apexcharts-toolbar {
    z-index: 1 !important;
  }
</style>
